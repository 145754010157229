import React, { useState } from "react";
import PropTypes from "prop-types";
import { Avatar, Card, CardContent, CardHeader, Divider, IconButton, Menu, MenuItem } from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import { green, grey, red } from "@mui/material/colors";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import AdminCommunityKeys from "./AdminCommunityKeys";
import { getColor } from "../../../utilities/materialAvatar";
import CreatedBy from "../../common/Components/CreatedBy";
import CommunityManagersList from "../../common/Components/CommunityManagersList";
import CommunityContactInfo from "../../common/Components/CommunityContactInfo";
import CommunityLocation from "../../common/Components/CommunityLocation";

import CommunitySetUnits from "../../common/Components/CommunitySetUnits";
import CommunitySetPropertyType from "../../common/Components/CommunitySetPropertyType";
import CommunitySetName from "../../common/Components/CommunitySetName";
import {
  adminCommunitySetActive,
  adminCommunitySetHidden,
  adminCommunitySetInactive,
  adminCommunitySetVisible,
} from "../../../reducers/admin-community-list";

import CommunityAllowHoldOpens from "../../common/Components/CommunityAllowHoldOpens";
import CommunityAllowSubkeys from "../../common/Components/CommunityAllowSubkeys";
import CommunityAllowAccessLogs from "../../common/Components/CommunityAllowAccessLogs";
import CommunityCreateAccountWithPreApprovedKeyDialog from "../../common/Components/CreateAccountPreApprovedKeyDialog";
import MemberList from "../../Members/Components/MemberList";
import CommunitySetCommercialBilling from "../../common/Components/CommunitySetCommercialBilling";

const styles = (theme) => ({
  root: {
    margin: 1,
    marginBottom: 3,
    overflow: "visible",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatarOffline: {
    backgroundColor: grey[500],
  },
  avatarOnline: {
    backgroundColor: green[400],
  },
  avatarOpen: {
    backgroundColor: green[900],
  },
  avatarDidNotOpen: {
    backgroundColor: red[900],
  },
});

function CommunitySubHeader(props) {
  const unitsString = props.community.number_of_units === 1 ? "Unit" : "Units";
  const hiddenString = props.community.hidden ? " ( Hidden )" : "";
  const activeString = props.community.active === 1 ? "" : props.community.active === 0 ? "( Deactivated )" : "";
  return (
    <Box>
      <Box onClick={props.unitsOpen}>
        {props.community.number_of_units} {unitsString}
      </Box>
      <Box>
        {activeString}
        {hiddenString}
      </Box>
    </Box>
  );
}

const MenuList = (props) => {
  const { community } = props;
  const active = community.active;
  const hidden = community.hidden;

  return (
    <>
      <Box>
        <MenuItem onClick={props._unitsOpen}>Set Units</MenuItem>
        <MenuItem onClick={props._nameOpen}>Set Community Name</MenuItem>
        <MenuItem onClick={props._openCreateAccount}>Add Member</MenuItem>
        <Divider />
        {active === 0 ? <MenuItem onClick={props._activateCommunity}>Activate</MenuItem> : null}
        {active === 1 ? <MenuItem onClick={props.openDialogBox}>Deactivate</MenuItem> : null}
        {hidden ? <MenuItem onClick={props._showCommunity}>Show</MenuItem> : null}
        {!hidden ? <MenuItem onClick={props._hideCommunity}>Hide</MenuItem> : null}
      </Box>
      <Dialog
        open={props.open}
        onClose={props.closeDialogBox}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to deactivate ${props.community.display_name} `}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Proceeding will deactivate {props.community.display_name}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.closeDialogBox}>Cancel</Button>
          <Button onClick={props.dialogDeactivation} autoFocus>
            Deactivate
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AdminCommunityItem = (props) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [unitsDialogOpen, setUnitsDialogOpen] = useState(false);
  const [nameDialogOpen, setNameDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [createAccountOpen, setCreateAccountOpen] = useState(false);
  const theme = useTheme();

  const _handleMenuPress = (event) => {
    if (!menuAnchor) {
      setMenuAnchor(event.currentTarget);
    }
  };

  const _handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const _activateCommunity = () => {
    props.dispatch(adminCommunitySetActive(props.community.id));
    _handleMenuClose();
  };

  const _deactivateCommunity = () => {
    props.dispatch(adminCommunitySetInactive(props.community.id));
    _handleMenuClose();
  };

  const _hideCommunity = () => {
    props.dispatch(adminCommunitySetHidden(props.community.id));
    _handleMenuClose();
  };

  const _showCommunity = () => {
    props.dispatch(adminCommunitySetVisible(props.community.id));
    _handleMenuClose();
  };

  const openDialogBox = () => {
    setOpen(true);
  };

  const openCreateAccount = () => {
    setCreateAccountOpen(true);
  };

  const closeCreateAccount = () => {
    setCreateAccountOpen(false);
  };

  const closeDialogBox = () => {
    setOpen(false);
  };

  const dialogDeactivation = () => {
    _deactivateCommunity();
    setOpen(false);
  };

  const _unitsOpen = () => {
    setUnitsDialogOpen(true);
    setMenuAnchor(null);
  };

  const _unitsClose = () => {
    setUnitsDialogOpen(false);
  };

  const _nameOpen = () => {
    setNameDialogOpen(true);
    setMenuAnchor(null);
  };

  const _nameClose = () => {
    setNameDialogOpen(false);
  };

  return (
    <>
      <Card sx={styles(theme).root} data-testid={"community-card"}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" sx={{ backgroundColor: getColor(props.community.id.toString()) }}>
              <HomeIcon />
            </Avatar>
          }
          action={
            <IconButton aria-label="settings" onClick={_handleMenuPress}>
              <MoreVertIcon />
              <Menu
                id="settings-menu"
                anchorEl={menuAnchor}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={_handleMenuClose}>
                <MenuList
                  community={props.community}
                  open={open}
                  _unitsOpen={_unitsOpen}
                  _nameOpen={_nameOpen}
                  _activateCommunity={_activateCommunity}
                  _hideCommunity={_hideCommunity}
                  openDialogBox={openDialogBox}
                  closeDialogBox={closeDialogBox}
                  dialogDeactivation={dialogDeactivation}
                  _openCreateAccount={openCreateAccount}
                  _showCommunity={_showCommunity}
                />
              </Menu>
            </IconButton>
          }
          title={
            <div onClick={_nameOpen}>
              {props.community.display_name} ({props.community.id})
            </div>
          }
          subheader={<CommunitySubHeader unitsOpen={_unitsOpen} community={props.community} />}
        />

        <CommunitySetUnits
          community_id={props.community.id}
          units={props.community.number_of_units}
          closeCallback={_unitsClose}
          open={unitsDialogOpen}
        />
        <CommunitySetName
          community_id={props.community.id}
          name={props.community.display_name}
          closeCallback={_nameClose}
          open={nameDialogOpen}
        />
        <CommunityCreateAccountWithPreApprovedKeyDialog
          community_id={props.community.id}
          closeCallback={closeCreateAccount}
          open={createAccountOpen}
        />
        <CardContent>
          <CommunityContactInfo contact_info={props.community.contact_info} community_id={props.community.id} />
          <Divider sx={{ marginBottom: 3 }} />
          <CommunityAllowSubkeys community_id={props.community.id} allow_subkeys={props.community.allow_subkeys} />
          <CommunityAllowHoldOpens
            community_id={props.community.id}
            allow_hold_opens={props.community.allow_hold_opens}
          />
          <CommunitySetPropertyType community_id={props.community.id} community_type={props.community.community_type} />
          <CommunityAllowAccessLogs
            community_id={props.community.id}
            is_open_log_history_enabled={props.community.is_open_log_history_enabled}
          />
          <CommunitySetCommercialBilling
            community_id={props.community.id}
            commercial_key_billing_enabled={props.community.commercial_key_billing_enabled}
            commercial_key_billing_day_of_month={props.community.commercial_key_billing_day_of_month}
          />
          <Divider sx={{ marginBottom: 3 }} />
          <CommunityManagersList managers={props.community.community_managers} community_id={props.community.id} />
          <Divider sx={{ marginBottom: 3 }} />
          <CommunityLocation location={props.community.location} community_id={props.community.id} />
          {/*<CommunityPaymentInfo*/}
          {/*  community_id={props.community.id}*/}
          {/*  payment_amount={props.community.payment_amount}*/}
          {/*  next_payment={props.community.next_payment}*/}
          {/*/>*/}
          <Divider sx={{ marginBottom: 3 }} />
          <AdminCommunityKeys type={"admin"} community_keys={props.community.keys} />
          <Divider sx={{ marginBottom: 3, marginTop: 3 }} />
          <CreatedBy creator={props.community.created_by} />
          <Divider sx={{ marginBottom: 3, marginTop: 3 }} />
          <MemberList type={"admin"} community_id={props.community.id} community={props.community} />
        </CardContent>
      </Card>
    </>
  );
};

AdminCommunityItem.propTypes = {
  type: PropTypes.string.isRequired,
  community: PropTypes.object.isRequired,
};

export default connect()(AdminCommunityItem);
